import React, { useEffect, useCallback } from 'react';
import './LinePage.css';
import LoginContainer from '../LoginContainer/LoginContainer';
import { Link, useNavigate } from 'react-router-dom';
import {
    linePageTextLineBlockTitle,
    linePageTextChangeLoginTitle,
    linePageTextChangeStatusTitle,
    linePageTextTimeOutOnTitle,
    linePageTextTimeOutOffTitle,
    linePageTextChangeLogTitle,
} from '../../vendor/constants/constantsTextMyTitle';

const LinePage = ({ logInStatus, isTimeOut, setIsTimeOut, openTimeOutPopUp, closePopUp }) => {
    const navigate = useNavigate();
    const buttonValue = isTimeOut
        ? "Снять с перерыва"
        : "На перерыв";
    const textTitle = isTimeOut
        ? linePageTextTimeOutOnTitle
        : linePageTextTimeOutOffTitle;

    const handleTimeout = useCallback(() => {
        if (!isTimeOut) {
            const timerID = setTimeout(() => {
                navigate('/phone_call');
                closePopUp();
            }, 15000);
            return () => clearTimeout(timerID);
        }
    }, [navigate, closePopUp, isTimeOut]);

    useEffect(handleTimeout, [handleTimeout]);

    return (
        <main className='line-page'>
            <div className='line-page__container'>
                <div className='line-page__line-block'
                    mytitle={linePageTextLineBlockTitle}>
                    <LoginContainer logInStatus={logInStatus} isTimeOut={isTimeOut} />
                    <button
                        type='button'
                        className='button line-page__chenge-login'
                        mytitle={linePageTextChangeLoginTitle}>Сменить профиль</button>
                    <Link
                        to='/auth'
                        className='line-page__chenge-status'
                        mytitle={linePageTextChangeStatusTitle}><span /> Уйти с линии</Link>
                    <button
                        type='button'
                        className='button line-page__time-out'
                        onClick={isTimeOut ? setIsTimeOut : openTimeOutPopUp}
                        mytitle={textTitle}>{buttonValue}</button>
                </div>
                <div className='autth-page__log-block'>
                    <button
                        type='button'
                        className='button line-page__chenge-log'
                        mytitle={linePageTextChangeLogTitle}>Скачать лог</button>
                </div>
            </div>
        </main>
    );
};

export default LinePage;