import React from 'react';
import './FunctionalLink.css';
import { phoneCallTextLinkBarTitle } from '../../../vendor/constants/constantsTextMyTitle';


function FunctionalLink({ name, onClick }) {
    return (
        <div className='functional-link' myTitle={phoneCallTextLinkBarTitle}>
            <button
                type='button'
                className='functional-link__link'
                onClick={onClick}
                myTitle={phoneCallTextLinkBarTitle}>
                {name}
            </button>
        </div>
    )
}

export default FunctionalLink
