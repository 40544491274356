import React, { useEffect } from 'react';
import './PhoneCall.css';
import FormWidget from '../FormWidget/FormWidget';
import LinkBar from '../LinkBar/LinkBar';
import Telephony from '../Telephony/Telephony';

function PhoneCall({ time, setTime }) {

    useEffect(() => {
        setTime(0);
        let interval = null;
        interval = setInterval(() => {
            setTime((time) => time + 10);
        }, 10);
        return () => {
            clearInterval(interval);
        };
    }, [setTime]);

    return (
        <main className='phone-call'>
            <FormWidget time={time} />
            <LinkBar />
            <Telephony />
        </main>
    )
}

export default PhoneCall
