import React from 'react';
import './BreakEl.css';
import { popupTimeOutTextTitle } from "../../vendor/constants/constantsTextMyTitle.js";

export default function BreakEl({ value, checked, onChange }) {
    return (
        <label className='break-el'
            mytitle={popupTimeOutTextTitle}>
            <input
                type="checkbox"
                name={value}
                value={value}
                checked={checked}
                onChange={onChange}
                className='break-el__input'
            />{" "}
            {value}
        </label>
    );
}
