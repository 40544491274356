import React, { useState } from 'react';
import "./PopupTimeOut.css";
import PopupWithForm from '../PopupWithForm/PopupWithForm';
import stateBreaks from "../../vendor/constants/constantsBreaks";
import BreakEl from '../BreakEl/BreakEl';

export default function PopupTimeOut({ isOpen, onClose, onUpdateStatus }) {

    const [state, setState] = useState(stateBreaks);

    function handleSubmit(evt) {
        evt.preventDefault();
        let value = ''
        state.breaks.forEach((e) => e.checked && (value = e.value))
        onUpdateStatus(value);
        const { breaks } = state;
        const newBreaks = [...breaks];
        newBreaks.forEach((e) => e.checked = false)
        setState({ breaks: newBreaks });
    }

    function onHandleChange(e) {
        const { checked, name } = e.target;
        const { breaks } = state;
        const index = breaks.findIndex(item => item.value === name);
        const item = breaks[index];
        const newBreaks = [...breaks];
        newBreaks.forEach((e) => e.checked = false)
        newBreaks[index] = { ...item, checked };
        setState({ breaks: newBreaks });
    };

    return (
        <PopupWithForm
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            name='timeout'
            title="Выберите причину паузы" >
            {state.breaks.map((br, key) => (
                <BreakEl
                    key={key}
                    value={br.value}
                    checked={br.checked}
                    onChange={onHandleChange}
                />
            ))}
        </PopupWithForm>
    )
}
