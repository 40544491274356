const stateBreaks = {
    breaks: [
        { value: 'Перерыв', checked: false },
        { value: 'Обед', checked: false },
        { value: 'Технический перерыв', checked: false },
        { value: 'Обучение', checked: false },
        { value: 'Работа с РГ', checked: false },
        { value: 'Работа с ОКК', checked: false },
        { value: 'Наставничество', checked: false },
        { value: 'Уборная', checked: false },
        { value: 'Исходящий вызов', checked: false },
    ]
};

export default stateBreaks;