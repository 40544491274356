import React, { useState } from 'react';
import './LinkBar.css';
import FunctionalLink from './FunctionalLink/FunctionalLink';
import functionalLink from '../../vendor/constants/costantsFuncrionalLink';
import img from "../../images/LinkBar/demoImg.jpg";
import { phoneCallTextTitle, phoneCallTextLinkBarTitle } from '../../vendor/constants/constantsTextMyTitle';

function LinkBar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAllButton, setIsOpenAllButton] = useState(false);
    const classNameTestImg = isOpen ? 'link-bar__test-img open' : 'link-bar__test-img';
    const hendleOpenTestImg = () => {
        setIsOpen(true);
        setIsOpenAllButton(false);
    };
    const hendleOpenAllButton = () => {
        setIsOpenAllButton(true);
        setIsOpen(false);
    };

    return (
        <section className='link-bar'
            myTitle={phoneCallTextTitle}>
            <div className='link-bar__container'
                myTitle={phoneCallTextLinkBarTitle}>
                <FunctionalLink name={"Текущий заказ"} onClick={hendleOpenTestImg} />
                {functionalLink.map((link, key) => <FunctionalLink key={key} name={link.name} onClick={hendleOpenAllButton} />)}
            </div>
            {(isOpen || isOpenAllButton)
                && <a
                    href={img}
                    className="button link-bar__button"
                    target="_blank"
                    rel="noopener noreferrer" >
                    Открыть в новом окне</a>}
            <span className={classNameTestImg}></span>
            {isOpenAllButton && <div className='link-bar__all-button-text'><span>Тут откроется форма или нужный инструмент.</span>
                <p>Внимание! - форма закроется автоматически по завершении звонка. <br />Даже если воспользоваться кнопкой "Открыть в новом окне" </p>
                <span>Чтобы заполнить форму когда звонок завершен.<br /> Нажми на, "Открыть в новом окне" средней клавишей мыши.</span>
            </div>}
        </section>
    );
}

export default LinkBar;