import React from 'react';
import './LoginContainer.css';
import hello from '../../images/AuthPage/hello.svg';
import {
    loginContainerTextOfflineTitle,
    loginContainerTextOnlineTitle,
    loginContainerTextTimeOutTitle
} from '../../vendor/constants/constantsTextMyTitle';
const LoginContainer = ({ logInStatus, isTimeOut }) => {

    const textTitle = window.location.pathname === "/working_page"
        ? (isTimeOut
            ? loginContainerTextTimeOutTitle
            : loginContainerTextOnlineTitle)
        : loginContainerTextOfflineTitle;


    const statusClass = window.location.pathname === "/working_page"
        ? (isTimeOut
            ? "login-container__status active time-out"
            : "login-container__status active")
        : "login-container__status";

    const statusValue = window.location.pathname === "/working_page"
        ? (isTimeOut
            ? logInStatus
            : "онлайн")
        : "оффлайн";

    return (
        <div className='login-container'
            mytitle={textTitle}>
            <img src={hello} alt='hello' className='login-container__img'
                mytitle={textTitle} />
            <div className='login-container__content'
                mytitle={textTitle}>
                <p className='login-container__name'
                    mytitle={textTitle}>Твой_логин</p>
                <p className={statusClass}
                    mytitle={textTitle}>{statusValue}</p>
            </div>
        </div>
    );
};

export default LoginContainer;