import React, { useState } from "react";
import "./StartPage.css";
import lockImg from "../../images/StartPage/lock.png";
import { Link } from "react-router-dom";
import { startPageTextAlertTitle, startPageTextDetailsTitle } from "../../vendor/constants/constantsTextMyTitle";

function StartPage() {

    const [isSelect, setIsSelect] = useState(false);

    const valueButton = isSelect
        ? "Скрыть Подробности ▲"
        : "Подробности ▼"

    const detailsClassName = isSelect
        ? "start-page__details-container active"
        : "start-page__details-container"

    return (
        <main className="start-page">
            <div className="start-page__alert-container"
                mytitle={startPageTextAlertTitle} >
                <img className="start-page__lock-img" src={lockImg} alt="замок"
                    mytitle={startPageTextAlertTitle} />
                <div className="start-page__alert-content"
                    mytitle={startPageTextAlertTitle}>
                    <h2 className="start-page__alert-title"
                        mytitle={startPageTextAlertTitle}>
                        Невозможно установить безопасное соединение
                    </h2>
                    <p className="start-page__discription"
                        mytitle={startPageTextAlertTitle}>
                        Злоумышленники могут похитить ваши данные сайта<br />
                        <span>https://имя_сайта</span> (например, пароли, сообщения или
                        номера банковских карт)
                    </p>
                    <p className="start-page__err-msg"
                        mytitle={startPageTextAlertTitle}>net::ERR_CERT_AUTHORITY_INVALID</p>
                    <div className="start-page__link-list">
                        <Link to="/" className="start-page_link">Закрыть вкладку</Link>
                        <button type="button" className="start-page__button" onClick={() => setIsSelect(!isSelect)} >{valueButton}</button>
                    </div>
                </div>
            </div>
            <div className={detailsClassName}
                mytitle={startPageTextDetailsTitle} >
                <p className="start-page__details-text"
                    mytitle={startPageTextDetailsTitle} >
                    Сервер не может подтвердить связь<br />
                    с доменом <span>https://имя_сайта</span>. Его сертификат безопасности не принимается операционной системой вашего устройства.{" "}
                    Возможно, проблема связана с настройками сервера или действиями злоумышленников,{" "}
                    которые пытаются перехватить данные.</p>
                <div className="start-page__details-link-list">
                    <Link to='/auth' className="start-page__details-link">Сделать исключение для этого сайта</Link>
                    <p className="start-page__details-alert">Это небезопасно</p>
                </div>
            </div>
        </main>
    );
}

export default StartPage;
