const functionalLink = [
    { name: "Поиск по клиентам" },
    { name: "Поиск по курьерам" },
    { name: "Админка Доставки Старые заказы DC" },
    { name: "Админка Доставки Ритейл" },
    { name: "Поиск по ресторанам" },
    { name: "Админка баланса" },
    { name: "Payture" },
    { name: "Заблокировать ВУ или ТС" },
    { name: "Отправка смс" },
    { name: "Новая форма L2" },
    { name: "Эскалация на L2" },
    { name: "Антифрод" },
    { name: "Информ поддержка" },
    { name: "Urgent" },
    { name: "Жалоба на курьера" },
    { name: "Запросы по форме от L2" },
    { name: "КЦ Cупервайзеров" },
    { name: "Эскалация на sales" },
    { name: "Техсап" },
    { name: "SuptechBot" },
    { name: "Внут.трекер" },
    { name: "Внеш.трекер" },
    { name: "Логика Еды" },
    { name: "Клиентская логика" },
    { name: "Памятка оператора курьерской линии" },
    { name: "Жалоба на оператора" },
];

export default functionalLink;