import React from 'react';
import './ThemeEl.css';

function ThemeEl({ arr, setArr, value }) {
    return (
        <div className='theme-el'>
            <p>{value}</p>
            <span onClick={() => {
                const index = arr.indexOf(value);
                arr.splice(index, 1);
                setArr(arr);
            }} />
        </div>
    )
}

export default ThemeEl
