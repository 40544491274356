import React, { useState, useEffect, useRef } from 'react'; import './MyTitle.css';

const MyTitle = () => {
    const myTitleRef = useRef(null);
    const [title, setTitle] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const classNameMyTitle = title ? 'my-title active' : 'my-title';

    useEffect(() => {
        const updatePositionAndTitle = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });

            const hoveredElement = document.elementFromPoint(e.clientX, e.clientY);
            if (hoveredElement.getAttribute('mytitle')) {
                setTitle(hoveredElement.getAttribute('mytitle'));
            } else {
                setTitle('');
            }
        };

        document.addEventListener('mousemove', updatePositionAndTitle);

        return () => {
            document.removeEventListener('mousemove', updatePositionAndTitle);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        const handleResizeHeight = () => {
            setWindowHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResizeHeight);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResizeHeight);
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth, windowHeight]);

    return (
        <div ref={myTitleRef} className={classNameMyTitle}
            style={{
                left: position.x < windowWidth / 2 ? position.x + 'px' : 'auto',
                right: position.x >= windowWidth / 2 ? (windowWidth - position.x) + 'px' : 'auto',
                top: position.y < windowHeight / 2 ? position.y + 'px' : 'auto',
                bottom: position.y >= windowHeight / 2 ? (windowHeight - position.y) + 'px' : 'auto',
            }}>
            {title && title}

        </div>);
};

export default MyTitle;