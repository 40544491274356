import React, { useState } from 'react';
import { Route, Routes } from 'react-router';
import StartPage from './components/StartPage/StartPage';
import AuthPage from './components/AuthPage/AuthPage';
import LinePage from './components/LinePage/LinePage';
import PopupTimeOut from './components/PopupTimeOut/PopupTimeOut';
import PhoneCall from './components/PhoneCall/PhoneCall';
import MyTitle from './components/MyTitle/MyTitle';
import PvoPage from './components/PvoPage/PvoPage';

function App() {
  const [time, setTime] = useState(0);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [logInStatus, setLogInStatus] = useState('Онлайн');
  const [isTimeOutPopUp, setIsTimeOutPopUp] = useState(false);

  function openTimeOutPopUp() {
    setIsTimeOutPopUp(true)
  };

  function closeAllPopUp() {
    setIsTimeOutPopUp(false)
  };

  function onUpdateStatus(v) {
    setLogInStatus(v)
    setIsTimeOut(true)
    closeAllPopUp()
  };

  function onChangeTimeOut() {
    setIsTimeOut(false)
  }
  return (
    <>
      <Routes>
        <Route path='/' element={
          <StartPage />
        } />
        <Route path='/auth' element={
          <AuthPage
            isTimeOut={isTimeOut} />
        } />
        <Route path='/working_page' element={
          <LinePage
            logInStatus={logInStatus}
            isTimeOut={isTimeOut}
            setIsTimeOut={onChangeTimeOut}
            openTimeOutPopUp={openTimeOutPopUp}
            closePopUp={closeAllPopUp}
          />
        } />
        <Route path='/phone_call' element={
          <PhoneCall time={time} setTime={setTime} />
        } />
        <Route path='/pvo' element={
          <PvoPage time={time} />
        } />
      </Routes>
      <footer className='footer'>
        <p className='footer__text'>&#169; Седов Дмитрий Сергеевич 2024г</p>
      </footer>
      <PopupTimeOut
        isOpen={isTimeOutPopUp}
        onClose={closeAllPopUp}
        onUpdateStatus={onUpdateStatus}
      />
      <MyTitle />
    </>
  );
}
export default App;
