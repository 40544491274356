import React, { useEffect, useState } from 'react';
import './PvoPage.css';
import LinkBar from '../LinkBar/LinkBar.js';
import Telephony from '../Telephony/Telephony.js';
import FormWidget from '../FormWidget/FormWidget.js';
import { useNavigate } from 'react-router';

function PvoPage({ time }) {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(30000);

    function handleExitPage() {
        navigate('/working_page');
    }

    useEffect(() => {
        let interval = setInterval(() => {
            if (timer <= 0) {
                clearInterval(interval);
                handleExitPage();
            } else {
                setTimer(timer - 1000);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    return (
        <main className='pvo-page'>
            <FormWidget time={time} timer={timer} exitPage={handleExitPage} />
            <LinkBar />
            <Telephony />
        </main>
    )
}

export default PvoPage;
