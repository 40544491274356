//комментарии к StartPage 
export const startPageTextAlertTitle = 'Предупреждение - может появляться при попытке зайти в NOC, это нормально - нажми "Подробности"';
export const startPageTextDetailsTitle = 'Предупреждение - сделайте исключение для этого сайта, чтобы NOC открылся';
//комментарии к LoginContainer
export const loginContainerTextOfflineTitle = 'Логин и статус - при входе в NOC автоматически ставится статус "оффлайн", звонки в нем не поступают.';
export const loginContainerTextOnlineTitle = 'Логин и статус - статус изменился на "онлайн" - значит, звонок может поступить в любой момент.';
export const loginContainerTextTimeOutTitle = 'Логин и статус - статус изменился на "перерыв", звонки в нем не поступают.';
//комментарии к AuthPage
export const authPageTextChangeLoginTitle = 'Сменить профиль - разлогиниться в системе';
export const authPageTextChangeStatusTitle = 'Выйти на линию - нажмите, чтобы начать принимать входящие звонки';
export const authPageTextChangeLogTitle = 'Скачать лог - можно скачать служебную информацию по запросу ТЛ или техсапа';
//комментарии к LinePage
export const linePageTextLineBlockTitle = 'Пока звонок не пришёл, рабочая панель будет выглядеть так';
export const linePageTextChangeLoginTitle = 'Сменить профиль - разлогиниться в системе';
export const linePageTextChangeStatusTitle = 'Уйти с линии - завершает работу на линии, статус меняется на "оффлайн".';
export const linePageTextTimeOutOnTitle = 'Снять с перерыва - нажимаем, чтобы продолжить принимать звонки';
export const linePageTextTimeOutOffTitle = 'На перерыв - нажимаем, чтобы уйти на перерыв или сделать исходящий звонок';
export const linePageTextChangeLogTitle = 'Скачать лог - можно скачать служебную информацию по запросу ТЛ или техсапа';
//комментарии к PopupTimeOut
export const popupTimeOutTextTitle = 'Окно выбора перерыва - выберите перерыв или исходящий звонок, во время выбора может поступить звонок';
//комментарии к PhoneCall
export const phoneCallTextTitle = 'Звонок - так выглядит активный входящий звонок. нажимай на поля и кнопки которые хочешь посмотреть.';
export const phoneCallTextTimerTitle = 'Время звонка - показывает общее время звонка';
export const phoneCallTextWhoApplingTitle = 'Кто обращается - показывает от кого поступил звонок';
export const phoneCallTextThemeTitle = 'Тема обращения - нужно выбрать тему обращения';
export const phoneCallTextThemeListTitle = 'Тематики - так отображаются выбранные в поле "Тема обращения" тематики по "Логике"';
export const phoneCallTextTextAreaTitle = 'Содержимое обращения - можно оставить пустым или в ходе разговора пользоваться как блокнотом, для записи важной информации.';
export const phoneCallTextSolutionTitle = 'Решено самостоятельно - используется для завершения звонка после решения обращения клиента, когда все поля заполнены';
export const phoneCallTextNumberTitle = 'Номер телефона, с которого звонили - показывает номер телефона, с которого поступил звонок.';
export const phoneCallTextIdTitle = 'ID звонка - нужен для заполнения разных форм.';
export const phoneCallTextLinkBarTitle = 'Функциональные кнопки - помогут открыть нужную форму или перейти в другой инструмент. Логику, Трекер, Админку и т.д.';
export const phoneCallTextTelephonyTitle = 'Телефония - позволяет совершить основные функции звонка.';
export const phoneCallTextMuteTitle = 'Mute - позволяет отключить микрофон';
export const phoneCallTextHoldTitle = 'Пауза (Hold) - позволяет поставить/снять удержание звонка';
export const phoneCallTextSizeTitle = 'Выпадающие окно - покажет или уберет дополнительные функции телефонии';
export const phoneCallTextCompleteTitle = 'Завершить - полностью завершает звонок';
export const phoneCallTextExitLineTitle = 'Уйти с линии после "ПВО" - проставляет статус "оффлайн" сразу после завершения звонка.';
export const phoneCallTextPauseLineTitle2 = 'На перерыв после "ПВО" - проставляет статус "перерыв" сразу после завершения звонка.';
export const phoneCallTextCallFormTitle = 'Номер телефона - сюда вводить номер телефона для исходящего звонка.';
export const phoneCallTextExtensionNumberTitle = 'Добавочный - сюда можно ввести добавочный номер для исходящего звонка или отправить тоновый сигнал.';
export const phoneCallTextChangeLogTitle = 'Скачать лог - можно скачать служебную информацию по запросу ТЛ или техсапа';
export const phoneCallTextTransferTitle = 'Перевести - нужна для эскалации на другии линии, Переводить можно из холда или без него.';
export const phoneCallTextHoldSecTitle = 'Холд + Исходящий - ставит на удержание звонок и совершает исходящий вызов.';
export const phoneCallTextEndCallTitle = 'Завершить исходящий - завершает исходящий звонок.';
export const phoneCallTextConnectTitle = 'Соединить звонки - соединяет звонки, используется только при эскалации на "Л2"';
export const phoneCallTextSwitchTitle = 'Переключиться между звонками - позволяет переключиться между собеседниками в одном обращении.';
// комментарии к PvoPage
export const pvoPageTextTimerTitle = 'Счетчик ПВО - "ПВО" - поствызывная обработка, то есть время на обработку звонка после его завершения.';
export const pvoPageTextSolutionTitle = 'Решено самостоятельно - кнопка становится активной, можно завершить "ПВО".';