import React from 'react';
import './TelephonyButtonList.css';
import { useNavigate } from 'react-router';
import {
    phoneCallTextCompleteTitle,
    phoneCallTextHoldTitle,
    phoneCallTextMuteTitle,
    phoneCallTextSizeTitle
} from '../../../vendor/constants/constantsTextMyTitle';

function TelephonyButtonList({ isOpen, onClickOpen }) {

    const navigate = useNavigate()

    const classNameSizeButton = isOpen
        ? "button telephony-button-list__button telephony-button-list__button_size open"
        : "button telephony-button-list__button telephony-button-list__button_size"
    const classNameCompleteButton = isOpen
        ? 'button telephony-button-list__button telephony-button-list__button_complete open'
        : 'button telephony-button-list__button telephony-button-list__button_complete'
    const classNameButtonContainer = isOpen
        ? "telephony-button-list__button-container open"
        : "telephony-button-list__button-container"

    return (
        <div className='telephony-button-list'>
            <div className={classNameButtonContainer}>
                <div>
                    <button
                        type='button'
                        className='button telephony-button-list__button telephony-button-list__button_mute'
                        myTitle={phoneCallTextMuteTitle} />
                    <button
                        type='button'
                        className='button telephony-button-list__button telephony-button-list__button_hold'
                        myTitle={phoneCallTextHoldTitle} />
                </div>
                <button
                    type='button'
                    className={classNameSizeButton}
                    onClick={onClickOpen}
                    myTitle={phoneCallTextSizeTitle}
                />
            </div>
            <button
                type='button'
                disabled={(window.location.pathname === '/pvo') ? true : false}
                className={classNameCompleteButton}
                onClick={() => navigate('/pvo')}
                myTitle={phoneCallTextCompleteTitle} >
                {isOpen ? "Завершить" : ''}
            </button>
        </div>
    )
}

export default TelephonyButtonList
