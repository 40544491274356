import React, { useState } from 'react'
import "./FormWidget.css"
import ThemeEl from './ThemeEl/ThemeEl';
import Timer from './Timer/Timer';
import {
    phoneCallTextWhoApplingTitle,
    phoneCallTextThemeTitle,
    phoneCallTextThemeListTitle,
    phoneCallTextTextAreaTitle,
    phoneCallTextSolutionTitle,
    phoneCallTextNumberTitle,
    phoneCallTextIdTitle,
    pvoPageTextSolutionTitle
} from "../../vendor/constants/constantsTextMyTitle.js";


function FormWidget({ time, timer, exitPage }) {
    const dataList = [
        "Клиенты|Не нашел подходящей|Не нашел подходящей",
        "Клиенты|Опоздание заказа/Доставили раньше|Опоздания нет",
        "Клиенты|Оплата|Вопрос по списаниям"

    ];
    const [themeValue, setThemeValue] = useState('')
    const [themList, setThemeList] = useState([])

    function handleChangeTheme(evt) {
        setThemeValue(evt.target.value);
    }
    function handleSubmitTheme(evt) {
        evt.preventDefault();
        const index = dataList.indexOf(themeValue);
        if (index !== -1) {
            setThemeList(themeValue.split("|"));
            console.log(themList);
            setThemeValue('');
        }
    }

    return (
        <section className='form-widget'>
            <div className='form-widget__timer-container'>
                <Timer time={time} color='' />
                {(window.location.pathname === '/pvo')
                    && <Timer time={timer} color='red' />
                }</div>
            <form
                className='form-widget__who-applying-container'
                myTitle={phoneCallTextWhoApplingTitle}>
                <p className='form-widget__label'
                    myTitle={phoneCallTextWhoApplingTitle}><span>*</span> Кто обращается </p>
                <input className='form-widget__input-datalist' type="text" defaultValue="Клиент" list="applying" />
                <datalist id="applying">
                    <option>Клиент</option>
                </datalist>
            </form>
            <form
                className='form-widget__theme-container'
                onSubmit={handleSubmitTheme}
                myTitle={phoneCallTextThemeTitle}>
                <p
                    className='form-widget__label'
                    myTitle={phoneCallTextThemeTitle}><span>*</span> Тема обращения </p>
                <input
                    className='form-widget__input-datalist'
                    name='theme-input'
                    type="text"
                    value={themeValue}
                    onChange={handleChangeTheme}
                    list="theme" />
                <datalist id="theme">
                    {dataList.map((e, key) => <option key={key} value={e} />)}
                </datalist>
            </form>
            <div
                className='form-widget__theme-list'
                myTitle={phoneCallTextThemeListTitle}
            >
                {themList && themList.map((e, key) => <ThemeEl key={key} arr={themList} setArr={setThemeList} value={e} />)}
            </div>
            <div
                className='form-widget__text-area-container'
                myTitle={phoneCallTextTextAreaTitle}>
                <p
                    className='form-widget__label'
                    myTitle={phoneCallTextTextAreaTitle}><span>*</span> Содержимое обращения </p>
                <textarea className='form-widget__text-area' formcontrolname="myText" adaptiveinputdirective="" />
            </div>
            {(window.location.pathname === '/pvo')
                ? (<p className='form-widget__solution-text'>Выбранная тематика всегда решается самостоятельно</p>)
                : (<div
                    className='form-widget__solution'
                    myTitle={phoneCallTextSolutionTitle} >
                    <input type='checkbox' disabled />
                    <p
                        className='form-widget__label'
                        myTitle={phoneCallTextSolutionTitle} > Решено самостоятельно </p>
                </div>)
            }

            <div
                className='form-widget__numder-container'
                myTitle={phoneCallTextNumberTitle}>
                <p
                    className='form-widget__label'
                    myTitle={phoneCallTextNumberTitle}> Номер телефона, с которого звонили </p>
                <div myTitle={phoneCallTextNumberTitle}>
                    <span myTitle={phoneCallTextNumberTitle}>+7(000)000-00-00</span>
                    <button type='button'>Копировать</button>
                </div>
            </div>
            <div
                className='form-widget__id-container'
                myTitle={phoneCallTextIdTitle}>
                <p
                    className='form-widget__label'
                    myTitle={phoneCallTextIdTitle}> ID звонка </p>
                <div myTitle={phoneCallTextIdTitle}>
                    <span myTitle={phoneCallTextIdTitle}>ID звонка</span>
                    <button type='button'>Копировать</button>
                </div>
            </div>
            {(window.location.pathname === '/pvo')
                && (
                    <button
                        type="button"
                        className='button form-widget__complete-button'
                        onClick={exitPage}
                        myTitle={pvoPageTextSolutionTitle}>
                        <span className='form-widget__checbox-img' />
                        <span>Решить самостоятельно</span>
                        <span className='form-widget__alt-img form-widget__alt-img_position' >ALT</span>
                        <span className='form-widget__alt-img' >ENTER</span>
                    </button>
                )}

        </section >
    )
}

export default FormWidget
