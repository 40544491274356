import React from "react";
import './Timer.css';
import { phoneCallTextTimerTitle, pvoPageTextTimerTitle } from "../../../vendor/constants/constantsTextMyTitle.js";
export default function Timer({ time, color }) {
    const classNameTimer = color
        ? 'timer-pvo'
        : 'timer';
    return (
        <div className={classNameTimer}
        >
            <span mytitle={color ? pvoPageTextTimerTitle : phoneCallTextTimerTitle} >
                {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
            </span>
            <span mytitle={color ? pvoPageTextTimerTitle : phoneCallTextTimerTitle} >
                {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
            </span>
        </div>
    );
}