import React from "react";
import './PopupWithForm.css'
import { popupTimeOutTextTitle } from "../../vendor/constants/constantsTextMyTitle";

function PopupWithForm({ isOpen, onClose, onSubmit, name, title, children }) {
    const className = isOpen
        ? `popup popup_type_${name} popup_opened`
        : `popup popup_type_${name}`;

    const buttonClassName = `button popup__submit-button_${name}`

    function handleOverlayClick(evt) {
        if (evt.target === evt.currentTarget) {
            onClose();
        }
    }

    return (
        <div className={className}>
            <div
                className="popup__body"
                onClick={handleOverlayClick}>

                <form
                    action="#"
                    name={name}
                    className="popup__form"
                    onSubmit={onSubmit}
                    mytitle={popupTimeOutTextTitle}
                >
                    <div className="popup__form-content"
                        mytitle={popupTimeOutTextTitle}>
                        <h2 className="popup__title"
                            mytitle={popupTimeOutTextTitle}>{title}</h2>
                        <div className="popup__children-container"
                            mytitle={popupTimeOutTextTitle}>
                            {children}
                        </div>
                        <div className="popup__button-list">
                            <button
                                type="submit"
                                className={buttonClassName}>
                                Применить
                            </button>
                            <button
                                type="button"
                                className="button"
                                onClick={onClose}>
                                Отмена
                            </button>
                        </div>
                        <button
                            type="button"
                            className="popup__close-button"
                            onClick={onClose}
                        ></button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PopupWithForm;