import React, { useState } from 'react';
import './Telephony.css';
import TelephonyButtonList from './TelephonyButtonList/TelephonyButtonList';
import {
    phoneCallTextExitLineTitle,
    phoneCallTextPauseLineTitle2,
    phoneCallTextTelephonyTitle,
    phoneCallTextCallFormTitle,
    phoneCallTextExtensionNumberTitle,
    phoneCallTextChangeLogTitle,
    phoneCallTextTransferTitle,
    phoneCallTextHoldSecTitle,
    phoneCallTextEndCallTitle,
    phoneCallTextConnectTitle,
    phoneCallTextSwitchTitle,
} from '../../vendor/constants/constantsTextMyTitle.js';

function Telephony() {
    const phone = "+7(000)000-00-00";
    const [isOpen, setIsOpen] = useState(false);
    const [isCallFormSubmit, setIsCallFormSubmit] = useState(false)
    const [callFormValue, setCallFormValue] = useState('')


    function handlerIsOpen() {
        setIsOpen(!isOpen)
    }
    function handleChangeNumber(evt) {
        setCallFormValue(evt.target.value);
    }
    function heandleSubmitNumber(evt) {
        evt.preventDefault();
        (callFormValue === phone)
            ? setIsCallFormSubmit(true)
            : setIsCallFormSubmit(false)
    }

    const classNameTelephony = isOpen
        ? 'telephony open'
        : 'telephony'
    const classNameBlock1 = isOpen
        ? 'telephony__block-1 open'
        : 'telephony__block-1'
    const classNameLogBlock = isOpen
        ? 'telephony__log-block open'
        : 'telephony__log-block'
    const classNameCallForm = isOpen
        ? 'telephony__call-form open'
        : 'telephony__call-form'

    const classNameCallButtonList = isCallFormSubmit && isOpen
        ? 'telephony__call-button-list active'
        : 'telephony__call-button-list'
    const classNameExtensionNumber = isOpen
        ? 'telephony__extension-number open'
        : 'telephony__extension-number'

    return (
        <section className={classNameTelephony}
            myTitle={phoneCallTextTelephonyTitle}>
            <div>
                <div className={classNameBlock1}>
                    <button
                        className='button telephony__block-1-button'
                        myTitle={phoneCallTextExitLineTitle}
                    ><span /> Уйти с линии после ПВО</button>
                    <button
                        className='button telephony__block-1-button'
                        myTitle={phoneCallTextPauseLineTitle2}>На перерыв после ПВО</button>
                </div>
            </div>
            <div className='telephony__block-2'>
                <form
                    className={classNameCallForm}
                    onSubmit={heandleSubmitNumber}
                    myTitle={phoneCallTextCallFormTitle}>
                    <input
                        className='telephony__call-form-input'
                        name='call-input'
                        type="text"
                        value={callFormValue}
                        placeholder='Введите номер'
                        onChange={handleChangeNumber}
                        list="number" />
                    <datalist id="number">
                        <option>{phone}</option>
                    </datalist>
                </form>
                <div className={classNameCallButtonList}>
                    <button
                        type='button'
                        className='button telephony__call-button'
                        myTitle={phoneCallTextTransferTitle}>Перевести</button>
                    <button
                        type='button'
                        className='button telephony__call-button'
                        myTitle={phoneCallTextHoldSecTitle}>Холд+Исходящий</button>
                    <button
                        type='button'
                        className='button telephony__call-button'
                        myTitle={phoneCallTextEndCallTitle}>Завершить исходящий</button>
                    <button
                        type='button'
                        className='button telephony__call-button'
                        myTitle={phoneCallTextConnectTitle}>Соединить звонки</button>
                    <button
                        type='button'
                        className='button telephony__call-button telephony__call-button_last-position'
                        myTitle={phoneCallTextSwitchTitle}>Переключиться между звонками</button>
                </div>
                <label className={classNameExtensionNumber}
                    myTitle={phoneCallTextExtensionNumberTitle} >
                    <input
                        type='text'
                        disabled
                        placeholder='Добавочный'
                        myTitle={phoneCallTextExtensionNumberTitle}
                    />
                    <span />
                </label>

                <div className={classNameLogBlock}>
                    <button
                        type='button'
                        className='button telephony__chenge-log'
                        myTitle={phoneCallTextChangeLogTitle}
                    >Скачать лог</button>
                </div>
                <TelephonyButtonList isOpen={isOpen} onClickOpen={handlerIsOpen} />
            </div>
        </section >
    )
}

export default Telephony
