import React from 'react';
import "./AuthPage.css";
import LoginContainer from '../LoginContainer/LoginContainer';
import { Link } from 'react-router-dom';
import { authPageTextChangeLogTitle, authPageTextChangeLoginTitle, authPageTextChangeStatusTitle } from '../../vendor/constants/constantsTextMyTitle';


export default function AuthPage({ isTimeOut }) {
    return (
        <main className='auth-page'>
            <div className='auth-page__container'>
                <div className='auth-page__auth-block'>
                    <LoginContainer isTimeOut={isTimeOut} />
                    <button
                        type='button'
                        className='button auth-page__chenge-login'
                        mytitle={authPageTextChangeLoginTitle}>
                        Сменить профиль
                    </button>
                    <Link to='/working_page' className='auth-page__chenge-status'
                        mytitle={authPageTextChangeStatusTitle}>
                        &#10145; Выйти на линию
                    </Link>
                </div>
                <div className='autth-page__log-block'>
                    <button
                        type='button'
                        className='button auth-page__chenge-log'
                        mytitle={authPageTextChangeLogTitle}>
                        Скачать лог
                    </button>
                </div>
            </div>
        </main>
    )
};
